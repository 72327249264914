/*
    @type: Component
    @name: Image.js
    @params:
        - children (sources of the picture)
        - classname (custom component class, given by parent),
        - url (url of the default image)
        - alt (string of the alternate text of the image)
        - id (possible id of the image)
        - fullsize (indicates if the image as to show without taking care of the container)
    @description:
        Displays the images if a figure + picture html 5 tag
 */

//Dependencies
import React, {useEffect, useRef} from "react";
import Lazyload from "../../LazyLoad";
import ScrollReveal from "scrollreveal";

//Styles
//import styles from "./Image.module.scss";

export default function Image ({src, srcRet, webp, webpRet, mobile, alt = 'image', classname, id = null}) {
    const image = useRef(null);

    useEffect(() => {
        ScrollReveal().reveal(image.current, {
            beforeReveal: (e) => {
                Lazyload(e);
            }
        });
    }, []);

    return (
        <figure className={classname}>
            <picture>
                {
                    mobile ? <source media="(max-width: 600px)" data-srcset={`${mobile}`} type={'image/jpeg'}/>
                        : null
                }

                {
                    webp || webpRet ?
                        <source data-srcset={
                            webp && webpRet ? `${webp} 1x, ${webpRet} 2x` : `${webp} 1x`
                        } type={'image/webp'}/>
                    : null
                }

                {
                    src || srcRet ?
                        <source data-srcset={
                            src && srcRet ? `${src} 1x, ${srcRet} 2x` : `${src} 1x`
                        } type={'image/jpeg'}/>
                    : null
                }

                <img data-src={src} alt={alt} ref={image}/>
            </picture>
        </figure>
    )
}
