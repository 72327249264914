import React from "react";

import etoroLogo from "../images/etoro-logo.svg";
import boursierLogo from "../images/boursier-logo.svg";

import styles from "./Header.module.scss"
import Image from "../components/Global/Image";


export default function Header () {
    return (
        <header className={styles.Main}>
            <div className={'container'}>
                <a href="https://ad.doubleclick.net/ddm/clk/507510052;314764148;y" target={'_blank'} rel={'noreferrer'} className={styles.Logo}>
                    <Image src={etoroLogo}/>
                </a>

                <span className={styles.Boursier}>
                    en partenariat avec
                    <Image src={boursierLogo} alt="Boursier.com"/>
                </span>
            </div>
        </header>
    )
}
