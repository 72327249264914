import React, {useRef, useEffect, useState} from "react";
import {gsap} from "gsap";
import {Swiper, SwiperSlide} from "swiper/react";
import Data from "./data.json";

import "swiper/swiper-bundle.css";

import styles from "./Pages.module.scss";
import Arrow from "./Pictos/Arrow";
import Wallet from "./Pictos/Wallet";
import isMobile from "ismobilejs";

export default function Pages() {

    const wrapper = useRef(null);
    const cursor = useRef(null);
    const pointer = useRef(null);

    const [right, setRight] = useState(30);
    const [data, setData] = useState(Data);


    useEffect(() => {
        setRight(((isMobile().phone ? 40 : 60) * window.innerWidth) / (isMobile().phone ? window.innerWidth : 1440));
    }, []);

    const cursorMove = (x, y) => {
        if (cursor.current) {
            gsap.set(cursor.current, {
                top: y - cursor.current.getBoundingClientRect().width / 2,
                left: x - cursor.current.getBoundingClientRect().width / 2
            })
        }
    };

    return (
        <div className={styles.Main}>
            <div className={styles.SliderWrapper} ref={wrapper}
                 onMouseLeave={() => gsap.to(cursor.current, .3, {opacity: 0})}
                 onMouseEnter={() => gsap.to(cursor.current, .3, {opacity: 1})}
                 onMouseMove={(e) => cursorMove(e.clientX, e.clientY)}>
                <Swiper slidesPerView={'auto'}
                        spaceBetween={right}
                        onTouchMove={(e) => {
                            cursorMove(e.touches.currentX, e.touches.currentY)
                        }}
                        lazy={true} preloadImages={true}
                        className={[styles.Slider].join(' ')}
                >
                    {
                        Object.values(data).reverse().map((item, index) => {
                            const image = require(`../images/pages/${item.index}.jpg`);
                            return (
                                <SwiperSlide className={styles.SlideItem} key={index}>
                                    <div className={styles.Content} style={{
                                        backgroundImage: `url(${image.default})`
                                    }}>
                                        <div>
                                            <h2 className={styles.Title}>
                                                {item.title}
                                            </h2>

                                            <div className={styles.Read}>
                                                <Arrow/> <span>Lire</span>
                                            </div>
                                        </div>

                                        <div className={styles.SeeWallet} onMouseEnter={() => {
                                            gsap.to(cursor.current, .3, {
                                                scale: .8
                                            })

                                            gsap.to(pointer.current, .3, {
                                                scale: 1
                                            })
                                        }}
                                             onMouseLeave={() => {
                                                 gsap.to(cursor.current, .3, {
                                                     scale: 1
                                                 })

                                                 gsap.to(pointer.current, .3, {
                                                     scale: 0
                                                 })
                                             }}>
                                            <a href={item.wallet}>
                                                <Wallet/> Voir le portefeuille associé
                                            </a>
                                        </div>
                                    </div>

                                    <a href={item.url} className={'link--absolute'}/>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>

                {
                    !isMobile().phone ?
                        <div ref={cursor} className={styles.Cursor}><span ref={pointer}/></div>
                        : null
                }
            </div>
        </div>
    )
}
