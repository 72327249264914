import React from "react";

export default function Arrow ({direction = 'bottom'}) {
    const svg = ` 
<svg width="25px" height="11px" viewBox="0 0 25 11" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="fleche" transform="translate(12.500000, 5.500000) rotate(-90.000000) translate(-12.500000, -5.500000) translate(7.000000, -7.000000)" fill="#FFFFFF">
            <rect id="Rectangle" x="4" y="0" width="2" height="16"></rect>
            <path d="M5.5,15.118034 L0.809016994,24.5 L10.190983,24.5 L5.5,15.118034 Z" id="Triangle" stroke="#FFFFFF" transform="translate(5.500000, 19.500000) scale(1, -1) translate(-5.500000, -19.500000) "></path>
        </g>
    </g>
</svg>`
    return (
        <i className={'icon'} dangerouslySetInnerHTML={{__html : svg}}/>
    )
}
