import React from "react";

import styles from "./Slider.module.scss";
import Pages from "./Pages";

export default function Slider () {
    return (
        <section className={styles.Main}>
            <div className="container">
                <div className={styles.Text}>
                    <h1>
                        Les grands formats
                    </h1>

                    <p>
                        Retrouvez les études passionnantes réalisés par
                        eToro et Boursier.com sur les marchés porteurs, ainsi que les portefeuilles associés
                    </p>
                </div>

                <Pages/>
            </div>
        </section>
    )
}
