export default function LazyLoad (e) {
    const sources = e.closest('picture').querySelectorAll('source');
    if (sources) {
        Object.values(sources).map(source => {
            const dataSrcSet = source.getAttribute('data-srcSet')
            if (dataSrcSet) {
                source.setAttribute('srcset', dataSrcSet);
                source.removeAttribute('data-srcset');
            }

            return true;
        })
    }

    if (e.getAttribute('data-src')) {
        e.src = e.getAttribute('data-src');
        e.removeAttribute('data-src');
    }
};
