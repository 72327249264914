import React from "react";

export default function Wallet () {
    const svg = `
<svg width="24px" height="20px" viewBox="0 0 24 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M1.8,0 C1.12482,0 0.6,0.52482 0.6,1.2 C0.6,1.87518 1.12482,2.4 1.8,2.4 L20.4,2.4 L20.4,0 L1.8,0 Z M0,2.78436 L0,18.300006 C0,19.131006 0.669,19.800006 1.5,19.800006 L22.5,19.800006 C23.331,19.800006 24,19.131006 24,18.300006 L24,15.600006 L14.7,15.600006 C13.87938,15.600006 13.2,14.920626 13.2,14.100006 L13.2,9.300006 C13.2,8.479383 13.87938,7.800003 14.7,7.800003 L24,7.800003 L24,5.1 C24,4.269 23.331,3.6 22.5,3.6 L1.51875,3.5814 C0.91761,3.50976 0.38178,3.21864 0,2.78436 L0,2.78436 Z M14.7,9.000003 C14.52342,9.000003 14.4,9.123423 14.4,9.300006 L14.4,14.100006 C14.4,14.276586 14.52342,14.400006 14.7,14.400006 L24,14.400006 L24,9 L14.7,9.000003 Z M17.7,10.800006 C18.19704,10.800006 18.6,11.202936 18.6,11.700006 C18.6,12.197076 18.19704,12.600006 17.7,12.600006 C17.20296,12.600006 16.8,12.197076 16.8,11.700006 C16.8,11.202936 17.20296,10.800006 17.7,10.800006 Z" id="Shape" fill="#74B743" fill-rule="nonzero"></path>
    </g>
</svg>`;

    return (
        <i className={'icon'} dangerouslySetInnerHTML={{__html:svg}}/>
    )
}
