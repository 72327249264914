import React, {useEffect} from "react";



import Header from "./partials/Header";
import { detectAnyAdblocker } from 'just-detect-adblock'
import Slider from "./components/Slider";

function App() {
    useEffect(() => {
        detectAnyAdblocker()
            .then(detected => {
                if (detected) {
                    const adButtons = document.querySelectorAll('[href*="https://ad.doubleclick.net/"]')
                    const newUrl = 'https://www.etoro.com/fr/copyportfolios/chip-tech?utm_medium=Media&utm_source=109189&utm_content=0&utm_serial=&utm_campaign=&utm_term=&dclid=CjgKEAjw7rWKBhDn97vwsP7ZtwMSJACfawvTQFc2jAlJNBZlPNdLvVUXnNPvUaOAGRV3zPURKgkqbPD_BwE';

                    Object.values(adButtons).map(button => button.href = newUrl)
                }
            })
    });

    return (
        <div className="App">
            <Header/>

            <Slider/>
        </div>
    );
}

export default App;
